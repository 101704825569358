<template>
 <v-container class="mt-5 pa-10">
   <h2 class="section-title mt-3 text-left ">{{
       this.t('title')
     }}</h2>
   <v-container>
     <v-row class="mt-3 mb-2">
       <v-col cols="12" md="8">{{this.t('totalQuestions')}}</v-col>
       <v-col cols="12" md="4">
         <v-row align="center">
           <v-col cols="12" md="2" class="contacts__container" >
             <img class="contacts__icon" src="../../assets/images/chat.png" alt="chat">
           </v-col>
           <v-col cols="12" md="10" class="pl-0">
             {{this.t('chat')}}
           </v-col>
         </v-row>
         <v-row align="center">
           <v-col cols="12" md="2" class="contacts__container">
             <img class="contacts__icon" src="../../assets/images/mail.png" alt="mail">
           </v-col>
           <v-col cols="12" md="10" class="pl-0">
            сontact@platforma.life
           </v-col>
         </v-row>
         <v-row align="center">
           <v-col cols="12" md="2" class="contacts__container">
             <img class="contacts__icon" src="../../assets/images/watsapp.png" alt="watsapp">
           </v-col>
           <v-col cols="12" md="10" class="pl-0">
           067-307-78-87
         </v-col>
         </v-row>
       </v-col>
     </v-row>
     <v-divider></v-divider>
     <v-row class="mt-3 mb-2">
       <v-col cols="12" md="8">{{this.t('corporateClients')}}</v-col>
       <v-col cols="12" md="4">
         <v-row align="center">
           <v-col cols="12" md="2"  class="contacts__container">
           </v-col>
           <v-col  cols="12" md="10" class="pl-0">
             <router-link to="/business">
               https://platforma.life/business
             </router-link>
           </v-col>
         </v-row>
         <v-row align="center">
           <v-col cols="12" md="2"  class="contacts__container">
             <img class="contacts__icon" src="../../assets/images/mail.png" alt="mail">
           </v-col>
           <v-col  cols="12" md="10" class="pl-0">
            business@platforma.life
          </v-col>
         </v-row>
       </v-col>
     </v-row>
     <v-divider></v-divider>
     <v-row class="mt-3 mb-2">
       <v-col cols="12" md="8">{{this.t('psychologistsPartnerships')}}</v-col>
       <v-col cols="12" md="4">
         <v-row align="center">
           <v-col cols="12" md="2"  class="contacts__container">
           </v-col>
           <v-col  cols="12" md="10" class="pl-0">
             <router-link to="/psychologist">
               https://platforma.life/psychologist
             </router-link>
          </v-col>
         </v-row>
         <v-row align="center">
           <v-col cols="12" md="2" class="contacts__container">
             <img class="contacts__icon" src="../../assets/images/mail.png" alt="mail">
           </v-col>
           <v-col  cols="12" md="10" class="pl-0">
              work@platforma.life
           </v-col>
         </v-row>
       </v-col>
     </v-row>
     <v-divider></v-divider>
     <v-row class="mt-3 mb-2">
       <v-col cols="12" md="8">{{this.t('advertising')}}</v-col>
       <v-col cols="12" md="4">
         <v-row align="center">
           <v-col cols="12" md="2" class="contacts__container">
             <img class="contacts__icon" src="../../assets/images/mail.png" alt="mail">
           </v-col>
           <v-col  cols="12" md="10" class="pl-0">
             marketing@platforma.life
           </v-col>
         </v-row>
       </v-col>
     </v-row>
   </v-container>
 </v-container>
</template>

<script>
export default {
  name: 'ContactsComponent',
  methods: {
    t: function (key) {
      return this.$vuetify.lang.t('$vuetify.main.contacts.' + key)
    }
  }
}

</script>

<style scoped>
.contacts__icon {
  max-width: 30px;
}
.contacts__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
